/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.faq-toggle').click(function(){
            var toggleButton = $(this).children('.fa');
            var faqAnswer = $(this).parents('li').children('span');
            console.log( faqAnswer);
            if ( toggleButton.hasClass('fa-plus') ){
                toggleButton.removeClass('fa-plus');
                toggleButton.addClass('fa-minus');
                faqAnswer.show();
            }
            else{
                toggleButton.removeClass('fa-minus');
                toggleButton.addClass('fa-plus');
                faqAnswer.hide();
            }
        });
        
        $('.breadcrumb-share').click(function(){
            $('.breadcrumb-share-buttons').show();
        });
        
        //hide share box on click outside
        $(document).mouseup(function (e)
        {
            var container = $(".breadcrumb-share-buttons");

            // if the target isn't the container... nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) 
            {
                container.hide();
            }
        });
        
        //submenu navigation width trigger
        var w = $(window).width();
        //menu breakpoint
        if ( w > 1190){
            //custom styles for navigation dropdown images
            $('.sub-menu').each( function(){

               var subMenu = $(this);
               var dropImage = $('a:first-child', this).first('nav-img-link').attr('href');

                if ( subMenu.hasClass("has-image") && dropImage ){
                    subMenu.css('width', '550');
                    subMenu.css('text-align', 'left');
                    subMenu.children('li').children('.nav-item-item').css('padding', '5px 10px 5px 15px');
                    subMenu.children('li').css('clear', 'both');

                    $(this).parent('li').hover(function(){

                    var subMenuHeight = $(this).children('.sub-menu').height();
                    var dropImageHeight = $(this).children('.sub-menu').find('.dropdown-image').last().height();

    //                console.log(subMenuHeight);
    //                console.log(dropImageHeight);

                        if ( dropImageHeight > subMenuHeight ){

                            $(this).children('.sub-menu').css( 'height', dropImageHeight + 35 );
                        }

                    });

                }
                else{
                    subMenu.find('.dd-image-title').css('padding', '0px');
                }
           }); 
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
