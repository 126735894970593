/*
ups-finder-auto-complete Scripts File
Author: Reshift Media Inc. , Mahmoud Ghali
*/
;(function($, google ){

    $(function(){ //on Dom Ready

        function validateLoc( $form ){
            if( $('.findLocationLat', $form ).val().length < 2 && $('.findLocationLng', $form ).val().length < 2 ){
                    console.log( 'false validation' );
                    return false;
            }
            return true;
        }

        if( $('.findLocationForm .findLocationTextBox').length > 0 ){

            var canadaBounds = new google.maps.LatLngBounds(
              new google.maps.LatLng(40.979898069620155, -143.4375),
              new google.maps.LatLng( 74.16408546675687, -49.921875 )
            );

            $('.findLocationForm').each(function( index ) {
                //console.log( $( this ) );
                console.log('This is the new Widget');

                var $form = $(this);
                var input = $('.findLocationTextBox', this);
                var autoCompleteSA_ = new google.maps.places.Autocomplete(input[0], { types: ['geocode'], bounds: canadaBounds, strictBounds: true } );
                // Avoid paying for data that you don't need by restricting the set of
                // place fields that are returned to just the address components.
                autoCompleteSA_.setFields(['formatted_address', 'geometry']);

                $( input ).keypress(function(e){
                    if ( e.which === 13 ) // Enter key = keycode 13
                    {
                            //e.preventDefault;//$(this).next().focus();  //Use whatever selector necessary to focus the 'next' input
                            return validateLoc($form);
                    }
                    return true;
                });

                autoCompleteSA_.addListener('place_changed', function() {
                  var place = autoCompleteSA_.getPlace();
                  console.log(place);
                  //console.log( place.geometry );
                  $( '.findLocationLat', $form ).val( place.geometry.location.lat() );
                  $( '.findLocationLng', $form ).val( place.geometry.location.lng() );
                  //console.log($( '.findLocationLng', $form ));
                });

                $( ".findLocationButton", this ).click(function(e){
                    if( $(input).val().length > 0 ){
                        return validateLoc($form);
                    }
                    return true;
                });

            });

        }
    });

})( jQuery, google );
